import { Link } from "gatsby"
import React from "react"
import ArrowLeft from "../assets/icons/arrow-left.svg"

const Backlink = () => (
  <Link to="/" className="group z-10 " label="Zurück">
    <div className="flex flex-row items-center justify-center text-black-900 relative z-1">
      <ArrowLeft className="svg w-12 h-12 group-hover:animate-bounceLeft text-gold-300 bg-white flex-shrink-0" />
      <p className="m-0 text-base default-link">Zurück</p>
    </div>
  </Link>
)

export default Backlink
